import React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import styled from "styled-components";
import Layout from "../components/layout";
import BlogList from "../components/blog-list";

const BlogStyles = styled.div`
  .page-title {
    padding: 4rem 3rem 5rem;

    h1 {
      font-size: 2.4rem;
      line-height: 3rem;
      border-bottom: 3px solid var(--text);
      padding-bottom: 1.5rem;
      margin-bottom: 3rem;
      text-transform: lowercase;
    }

    p {
      font-size: 1.4rem;
      line-height: 2.1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      margin-top: 2rem;
      text-transform: uppercase;
      color: var(--p-grey);
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: 981px) {
    .container {
      max-width: 1220px;
    }

    .page-title {
      padding: 8rem 0 9rem;

      .row {
        .col {
          flex-basis: 20%;

          &:last-child {
            flex-basis: 70%;
            margin-left: 10%;
          }
        }
      }

      h1 {
        font-size: 6rem;
        line-height: 6rem;
        margin-bottom: 8.5rem;
        padding-bottom: 2.5rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.8rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-top: 0;
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout className="blog-archive-page">
    <Seo post={data.wpgraphql.page} />

    <BlogStyles>
      <div className="page-title">
        <div className="container">
          <h1>{data.wpgraphql.page.title}</h1>
          <div className="row">
            <div className="col">
              <h2>{data.wpgraphql.page.acfBlog.title}</h2>
            </div>
            <div className="col">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wpgraphql.page.acfBlog.content,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <BlogList>{data.wpgraphql.posts.edges}</BlogList>
    </BlogStyles>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query GET_BLOG_POSTS {
    wpgraphql {
      posts(first: 10000, after: null) {
        edges {
          node {
            databaseId
            slug
            title
            date
            excerpt
            categories {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            content(format: RENDERED)
            featuredImage {
              node {
                altText
                title(format: RENDERED)
                mediaItemUrl
                slug
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid {
                      base64
                      tracedSVG
                      srcWebp
                      srcSetWebp
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
            author {
              node {
                name
              }
            }
          }
        }
      }
      page(id: "blog", idType: URI) {
        title
        content
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        acfBlog {
          content
          title
        }
      }
    }
  }
`;
