import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const BlogItemStyles = styled.div`
  margin-bottom: 3rem;
  display: none;

  &.show {
    display: block;
  }

  .gatsby-image-wrapper {
    padding-bottom: 100%;

    img {
      &:hover {
        opacity: 0.6 !important;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 700;
    margin: 2.5rem 0 1rem;
  }

  a {
    color: var(--dark-grey);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--blue);
    }
  }

  .meta {
    color: var(--p-grey);
    margin-bottom: 2rem;
  }

  .more {
    color: var(--blue);
    text-decoration: underline;
  }

  @media screen and (min-width: 981px) {
    flex-basis: calc(33.3333% - 0.5rem);
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    .blog-item-content {
      padding: 3rem;

      h3 {
        font-size: 2.4rem;
        line-height: 2.9rem;
        margin-top: 0;
      }
    }
  }
`;

const BlogItem = ({ children, show }) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(children.node.date);

  return (
    <BlogItemStyles key={children.node.slug} className={show ? "show" : null}>
      {null !== children.node.featuredImage.node && (
        <Link to={`/${children.node.slug}`}>
          <Img
            fluid={children.node.featuredImage.node.imageFile.childImageSharp.fluid}
            alt={children.node.title}
          />
        </Link>
      )}
      <div className="blog-item-content">
        <Link to={`/${children.node.slug}`}>
          <h3 dangerouslySetInnerHTML={{ __html: children.node.title }} />
        </Link>
        <div className="meta">
          by {children.node.author.node.name} | {date.toLocaleDateString("en-US", options)} |{" "}
          {children.node.categories.edges[0].node.name}
        </div>
        <Link to={`/${children.node.slug}`} className="more">
          read more
        </Link>
      </div>
    </BlogItemStyles>
  );
};

export default BlogItem;
