import React, { useState, useLayoutEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import BlogItem from "../components/blog-item";

const BlogListStyles = styled.div`
  padding: 0 3rem 2rem;

  .pager {
    text-align: center;
    padding: 2rem 0;
    font-size: 1.6rem;

    li {
      margin: 0 1rem;
    }

    a,
    span {
      width: 24px;
      height: 24px;
      display: block;
      line-height: 2.4rem;
      text-align: center;
      border-radius: 100%;
      text-decoration: none;
      transition: background 0.3s ease, color 0.3s ease;
      cursor: pointer;

      &:hover,
      &.active {
        font-weight: 700;
        color: var(--white);
        background: var(--blue);
      }

      &.prev,
      &.next {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 11px;
        height: 11px;
        border: 1px solid transparent;
        border-radius: 100px;
        color: var(--blue);

        &:hover,
        &.active {
          background: none;
        }
      }

      &.prev {
        &:after {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 5px;
          height: 5px;
          border-bottom: 1px solid;
          border-left: 1px solid;
          transform: rotate(45deg);
          left: 3px;
          top: 2px;
        }
      }

      &.next {
        &:after {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 5px;
          height: 5px;
          border-bottom: 1px solid;
          border-right: 1px solid;
          transform: rotate(-45deg);
          right: 3px;
          top: 2px;
        }
      }
    }
  }

  @media screen and (min-width: 981px) {
    .container {
      .row {
        flex-wrap: wrap;
        margin-left: -0.25rem;
        margin-right: -0.25rem;
      }
    }

    .pager {
      padding-bottom: 5rem;
    }
  }
`;

const BlogList = props => {
  const perPage = 6;
  const total = props.children.length;
  const pages = Array.from(Array(Math.ceil(total / perPage)).keys());
  const [page, setPage] = useState(1);
  const location =
    typeof window !== `undefined` ? window.location.href : `${process.env.SITE_URL}/blog/`;
  const url = new URL(location);
  let postList = "";

  const changePage = event => {
    const current = event.currentTarget;
    const currentPage = parseInt(current.getAttribute("data-page"));

    setPage(currentPage);
    current.classList.add("active");
    url.searchParams.append("page", currentPage);
    url.searchParams.set("page", currentPage);
    window.history.pushState({ path: location }, "", url);

    window.scrollTo({
      top: postList.offsetTop - 130,
      behavior: "smooth",
    });
  };

  const isShown = index => {
    const firstPage = page === 1 && index + 1 <= perPage;
    const lessThan = index + 1 <= page * perPage;
    const biggerThan = page > 1 && index + 1 > (page - 1) * perPage;

    return firstPage || (lessThan && biggerThan);
  };

  useLayoutEffect(() => {
    const currentPage = parseInt(url.searchParams.get("page"));
    postList = document.querySelector(".post-list");

    if (currentPage) {
      setPage(currentPage);
    }
  }, []);

  return (
    <BlogListStyles>
      <div className="container post-list">
        <div className="row">
          {props.children.map((post, index) => (
            <BlogItem key={post.slug} show={isShown(index)}>
              {post}
            </BlogItem>
          ))}
        </div>
        <nav className="pager">
          <ul>
            {page > 1 ? (
              <li>
                <span className="prev" data-page={page - 1} onClick={changePage}></span>
              </li>
            ) : null}
            {pages.map(value => (
              <li>
                <span
                  className={value + 1 === page ? "active" : null}
                  data-page={value + 1}
                  onClick={changePage}
                >
                  {value + 1}
                </span>
              </li>
            ))}
            {page < pages.length ? (
              <li>
                <span className="next" data-page={page + 1} onClick={changePage}></span>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </BlogListStyles>
  );
};

export default BlogList;
